import PEACE from "../assets/images/Armonia.png";
import PRIZE from "../assets/images/Incentivo.png";
import LEARNING from "../assets/images/Educacion.png";
import COFFEE from "../assets/images/Coffee.png";
import OFFICE from "../assets/images/Office.jpg";
import OFFICE1 from "../assets/images/Office1.jpg";
import OFFICE2 from "../assets/images/Office2.jpg";
import OFFICE3 from "../assets/images/Office3.jpg";
import OFFICE4 from "../assets/images/Office4.jpg";
import OFFICE5 from "../assets/images/Office5.jpg";
import OFFICE6 from "../assets/images/Office6.jpg";
import PRODUCT_ECO_APPS from "../assets/images/ProductEcoApps.jpg";
import PRODUCT_FINANCIAL_APPS from "../assets/images/ProductFinancialApps.jpg";
import PRODUCT_SAAS from "../assets/images/ProductSaaS.jpg";
import PRODUCT_PCI from "../assets/images/ProductPCI.jpg";
import PRODUCT_NP from "../assets/images/ProductNP.jpg";
import PRODUCT_IPC from "../assets/images/ProductIPC.jpg";
import PRODUCT_SURFING from "../assets/images/ProductSurfing.jpg";
import DEVELOPMENT from "../assets/images/UnobotDesarrollo.png";
import DESIGN1 from "../assets/images/Dis_Core.png";
import OPERATIONS from "../assets/images/UnobotOperations.png";
import TECHNICALSERVICE from "../assets/images/UnobotIT.png";
import UNOBOTHR from "../assets/images/UnobotHR.png";
import ADMINISTRACION from "../assets/images/UnobotAdmin.png";
import QA from "../assets/images/UnobotQA.png";
import IT from "../assets/images/UnobotWifi.png";
import DATAANALYTICS from "../assets/images/UnobotOrange.png";
import PROJECTS from "../assets/images/Projects.png";
import ARROW2 from "../assets/images/CheckIcon.png";
import ARROW1 from "../assets/images/Arrow1.png";
import ERROR from "../assets/images/error.png";
import LINE from "../assets/images/Line.png";
import LEMASPANISH from "../assets/images/LemaSpanish.png";
import TECHMINDSBACKGROUND from "../assets/images/TechmindsBakcground.png";
import LOGOITSU from "../assets/svg/LogoITSU.svg";
// import TECHMINDS1 from "../assets/images/TechMinds1.png";
import TECHMINDS2 from "../assets/images/Lightbuld.png";
import TECHMINDS3 from "../assets/images/CaligramaSpanish.png";
import DESIGN_DESCRIPTION from "../assets/images/Unobot_Descripcion.png";
import DEV_DESCRIPTION from "../assets/images/Unobot_Descripcion2.png";
import OP_DESCRIPTION from "../assets/images/OP_Description.png";
import PROYECTOS_DESCRIPTION from "../assets/images/Proyectos_Description.png";
import DATA_DESCRIPTION from "../assets/images/DA_Description.png";
import IT_DESCRIPTION from "../assets/images/IT_Description.png";
import TS_DESCRIPTION from "../assets/images/TS_Description.png";
import B2B_RESEARCH from "../assets/images/UnoBotsLanding_RESEARCH.png";
import B2B_MARKETING from "../assets/images/UnoBotsLanding_MARKETING.png";
import B2B_DESIGN from "../assets/images/UnoBotsLanding_DISEÑO.png";
import B2B_APROBATION from "../assets/images/UnoBotsLanding_APROBACION.png";
import B2B_DEVELOMENT from "../assets/images/UnoBotsLanding_DESARROLLO.png";

const BENEFITSLIST = [
	{
		benefit: "Armonía.",
		description:
			"No solo trabajarás con profesionales extraordinarios, sino que además tendrás espacios para el esparcimiento, actividades recreativas, y todas las condiciones de alimentación, estacionamiento, y servicios de salud.",
		icon: PEACE,
	},
	{
		benefit: "Compensamos a los mejores.",
		description:
			"Nos sentimos orgullosos de decir que los mejores talentos trabajan con nosotros. Valoramos el compromiso, la productividad  y el desempeño de cada uno de los integrantes de nuestro equipo reconociendo sus capacidades.",
		icon: PRIZE,
	},
	{
		benefit: "Desarrollo profesional.",
		description:
			"Tendrás acceso a herramientas y tecnologías de última generación, Wifi, formación ilimitada y personalizada desde plataformas e-learning hasta capacitaciones de manos de especialistas, y sala de estudio para los come libros.",
		icon: LEARNING,
	},
];

const BACK = "< Anterior";

const BACK_HOME = "< Regresar Inicio";

const COMPANY_HEADER = {
	active: "Company",
	sloganPt1: "Vive la experiencia ",
};

const COMPANY_BENEFITS = {
	pretitle: "Beneficios",
	subtitle: "¡Vive, Crea y Sueña!",
	text: "Queremos que ames a SitioUno tanto como nosotros. Aquí te contamos sobre nuestros beneficios.",
	headline: `… y solo por si te interesa, jamás te va a faltar el café.`,
	icon: COFFEE,
};

const CREW_HEADER = {
	number: "01",
	header: "Postúlate",
	subtitle: "Elige tu equipo",
};

const CREW = [
	{
		team: "Desarrollo",
		text: "Programadores, científicos de datos, informáticos, diseñadores gráficos, proyectistas y pasantes en carreras afines.",
		image: DEVELOPMENT,
	},
	{
		team: "Dpto. de Productos",
		text: "Testers, diseñadores de UI/UX, marketers, product owner, ingenieros de producción y pasantes en carreras afines.",
		image: QA,
	},
	{
		team: "Servicio Técnico",
		text: "Profesionales en el área de electrónica.",
		image: TECHNICALSERVICE,
	},
	{
		team: "Operaciones",
		text: "Seguridad, logística y almacén, servicios generales, cocina y mantenimiento.",
		image: OPERATIONS,
	},
	{
		team: "Gestión de TH y Jurídico",
		text: "Industriologos, psicólogos, educadores, abogados y profesionales de RRHH.",
		image: UNOBOTHR,
	},
	{
		team: "Administración",
		text: "Contadores, administradores y pasantes.",
		image: ADMINISTRACION,
	},
];

const FOOTER_LINKS = [
	{
		label: "Home",
		link: "/#Home",
		sections: [
			{
				label: "Nuestro Trabajo",
				link: "/#OurWork",
			},
			{
				label: "Oficina",
				link: "/#Workplace",
			},
			{
				label: "Beneficios",
				link: "/#CompanyBenefits",
			},
		],
	},
	{
		label: "Compañía",
		link: "/Company#Company",
		sections: [
			{
				label: "Equipos",
				link: "/Company#Teams",
			},
			{
				label: "Servicios B2B",
				link: "/B2B",
			},
			{
				label: "Apps",
				link: "/Apps",
			},
		],
	},
	{
		label: "Estudiantes",
		link: "/Students#Students",
		sections: [
			{
				label: "Beneficios",
				link: "/Students#Techminds",
			},
		],
	},
	{
		label: "Trabajos",
		link: "/Jobs#Crew",
		sections: [
			{
				label: `Únete`,
				link: "/Jobs#Crew",
				warning: `Estamos contratando`,
			},
		],
	},
	{
		label: "Más info",
		link: "/cookies-consent",
		sections: [
			{
				label: "Políticas de privacidad",
				link: "/PrivacyPolicy",
			},
			{
				label: "Términos y Condiciones",
				link: "/Terms&Conditions",
			},
			{
				label: "Politicas de Cookies",
				link: "/cookies-consent",
			},
		],
	},
];

const INTERN_BENEFITS = {
	header: "Beneficios para los estudiantes",
	subtitle: "¿Qué podemos ofrecer?",
};

const JOBS_HEADER = {
	active: "Jobs",
	sloganPt1: "Impulsa tu carrera",
	header: "Elige tu equipo",
};

const LEADER = {
	pretitle: "Principios de liderazgo",
	text: `La esencia de “Los Uno”`,
	header:
		"> Hacemos productos pensando en como mejoraremos la calidad de vida de cada uno de nuestros usuarios. Y eso incluye a la gente que conocemos_",
};

const LEADER_VALUES = [
	{
		title: "Nuestra misión",
		text: "En SitioUno, nuestro objetivo principal es la creación de soluciones tecnológicas innovadoras, seguras y adaptadas a las necesidades de los usuarios.",
	},
	{
		title: "Altos estándares",
		text: "Jamás nos conformamos con que nuestros productos sean “buenos”. Aspiramos a que sean los mejores y para ello, nos guiamos por los más altos estándares de calidad del mercado.",
	},
	{
		title: "Amamos nuestros productos",
		text: "Creemos en el sentido de pertenencia de cada empleado de nuestra empresa al expresar con orgullo que ayudó en la creación de un producto de gran calidad.",
	},
	{
		title: "Creemos en tí",
		text: "Nuestros principios se fundamentan en el aprendizaje continuo, creer en el potencial humano, fomentar la creatividad y pensar firmemente que siempre es posible mejorar como profesional y ser humano.",
	},
];

const FEATURES1 = {
	text: "Amamos los retos, amamos aprender y la mejora continua.",
};

const FEATURES2 = {
	text: "Nos entusiasma la gente que, a través de su criterio, agrega valor a nuestros productos y nuestra cultura.",
};

const FEATURES3 = {
	text: "Los entusiastas y apasionados que trabajan en lo que les gusta, sin duda tienen un lugar aquí.",
};

const FEATURES4 = {
	text: "Trabajamos en equipo, SIEMPRE.",
};

const FEATURES5 = {
	text: "Aprendemos, crecemos y triunfamos JUNTOS.",
};

const FEATURES6 = {
	text: "Hacemos productos pensando en como mejoraremos la calidad de vida de cada uno de nuestros usuarios. Y eso incluye a la gente que conocemos.",
};

const FEATURES_VALUES = [
	{
		text: "Amamos los retos, amamos aprender y la mejora continua.",
		line: LINE,
	},
	{
		text: "Nos entusiasma la gente que, a través de su criterio, agrega valor a nuestros productos y nuestra cultura.",
		line: LINE,
	},
	{
		text: "Los entusiastas y apasionados que trabajan en lo que les gusta, sin duda tienen un lugar aquí.",
		line: LINE,
	},
	{
		text: "Trabajamos en equipo, SIEMPRE.",
		line: LINE,
	},
	{
		text: "Aprendemos, crecemos y triunfamos JUNTOS.",
		line: LINE,
	},
	{
		text: "Hacemos productos pensando en como mejoraremos la calidad de vida de cada uno de nuestros usuarios. Y eso incluye a la gente que conocemos.",
		line: LINE,
	},
];

const NEXT = "Siguiente >";

const NAVLINKS = [
	{
		label: "",
		link: "/",
	},
	{
		label: "Compañía",
		link: "/Company",
	},
	{
		label: "Estudiantes",
		link: "/Students",
	},
	{
		label: "Empleo",
		link: "/Jobs",
	},
	{
		label: "Aplicativos",
		link: "/Apps",
	},
	{
		label: "Servicios B2B",
		link: "/B2B",
	},
	// {
	//   label: "Techminds academy",
	//   link: "/TechmindsAcademy",
	// },
];

const OFFICE_CAROUSEL = [
	{
		image: OFFICE,
		title: "",
		caption: "",
	},
	{
		image: OFFICE1,
		title: "",
		caption: "",
	},
	{
		image: OFFICE2,
		title: "",
		caption: "",
	},
	{
		image: OFFICE3,
		title: "",
		caption: "",
	},
	{
		image: OFFICE4,
		title: "",
		caption: "",
	},
	{
		image: OFFICE5,
		title: "",
		caption: "",
	},
	{
		image: OFFICE6,
		title: "",
		caption: "",
	},
];

const OUR_WORK = {
	pretitle: "About us",
	subtitle: "¿Quiénes somos?",
	text: "Somos un equipo de profesionales apasionados por la tecnología, dedicados al desarrollo de software para el mercado de medios de pagos, enfocados en brindar soluciones innovadoras para las tareas comerciales de nuestros usuarios, al tiempo que brindamos servicios de la más alta calidad a nuestros clientes.",
};

const OUR_WORK2 = {
	pretitle: "Our products",
	subtitle: "Nuestros productos",
	label: "nuevo",
	button: "VER APLICATIVOS",
};

const OUR_WORK3 = {
	pretitle: "Our products",
	text: "Be Soul es el espacio que te ayudará a mantener siempre vivo el recuerdo de tus seres queridos de forma segura.",
};

const OUR_WORK4 = {
	pretitle: "Our products",
	text: "Bienvenido a tu rockola digital donde podrás compartir tu Swagg con otras personas en diferentes sitios.",
};

const OUR_WORK5 = {
	text: "EAR es la plataforma que combina IA con gestión empresarial para optimizar tu empresa y mejorar la experiencia del cliente",
};

const OUR_WORK6 = {
	text: "HubFintech.ai es la solución de inteligencia artificial que revoluciona la banca y las fintech, optimizando operaciones y elevando la experiencia del cliente.",
};

const ABOUT_US = {
	pretitle: "About us",
	subtitle: "¿Cómo lo hacemos?",
};

const ABOUT_US1 = {
	number: "01",
	headline: 'Cultura del "We", NOSOTROS:',
	text2:
		"Nuestra gente en primer lugar. Seleccionamos a los mejores talentos y creemos en lo importante de crear las condiciones que cultiven el liderazgo compartido y la gerencia de puertas abiertas",
	text3:
		" Los invitamos a creer en su talento, a que amen lo que hacen, a seguir su pasión y a entender que su voz cuenta, pero lo hacemos juntos: nunca se trata de ellos o de ustedes, siempre se trata de nosotros.",
};

const ABOUT_US2 = {
	number: "02",
	headline: `Equipos de alto desempeño, “Los Uno”:`,
	text2:
		"La calidad de lo que hacemos es nuestra mejor insignia. Estar orgullosos de nuestros resultados, “pensar fuera de la caja” y trabajar en equipo, manteniendo el enfoque en el objetivo, son nuestras virtudes. Valoramos y reconocemos a los que se destacan y se superan constantemente.",
};

const ABOUT_US3 = {
	number: "03",
	headline: "SINERGIA, nuestro marco de trabajo: ",
	text2: `Somos el resultado de la integración de metodologías ágiles y las herramientas más sofisticadas y vanguardistas. Creemos en el poder de descubrir la belleza que representa convertir una idea en producto y practicamos el arte de hacer las cosas sencillas, pero no simples. `,
};

const PERFORMANCE = {
	pretitle: "Rendimiento",
	subtitle: "SitioUno en cifras.",
	text: "Nuestro trabajo en datos.",
};

const PERFORMANCE_COUNTERS = [
	{
		text2: "Transacciones por segundo",
	},
	{
		text2: "Transacciones diarias",
	},
	{
		text2: "Usuarios servidos",
	},
	{
		text2: "Notificaciones enviadas",
	},
];

const HEADER = {
	active: "Home",
	sloganPt1: "¡Estudia, diviértete, ",
	sloganBold: "haz historia",
	sloganPt2: "!",
	label1: "Conócenos",
	label2: "Únete al equipo",
	link1: "/Company",
	link2: "/Jobs",
};

const INTERN_QUOTE = {
	quote:
		"En Techmind, he tenido la oportunidad de aprender y comenzar mi vida laboral de la mejor forma posible, acompañado de excelentes profesionales que me brindan las herramientas necesarias para seguir adelante.",
	name: "- Cesar Guzmán",
	position: "Tech Mind Intern",
};

const PERSONAL_INFO = {
	number1: "02",
	header: "Información personal",
	subtitle: "Cuéntanos de tí",
	name: "Nombre",
	lastName: "Apellido",
	email: "Email",
	number: "Número de teléfono",
	next: "Next",
	nameValidation:
		"Campo requerido. Debe contener solo letras y espacios. Mínimo 2 caracteres.",
	emailValidation: "Campo requerido. Ingrese un email válido.",
	phoneValidation:
		"Campo requerido. Debe contener solo números (ej. 0414-3314576)",
};

const PRODUCTS = [
	{
		name: "PCI-DSS",
		description: `El Payment Card Industry Data Security Standard es el conjunto de normas de seguridad relacionado a las diferentes tarjetas de crédito y débito que hay en el mercado actualmente. Alcanzar esta certificación representa una meta importante, ya que pocas empresas consiguen este logro.`,
		image: PRODUCT_PCI,
	},
	{
		name: "Aplicativos financieros",
		description:
			"De gran calidad, seguros, estables y con el respaldo del mejor servicio técnico, los aplicativos que son creados en SitioUno se ajustan de manera flexible y adecuada a todas las necesidades de los usuarios, sin abandonar un diseño muy trabajado y refinado.",
		image: PRODUCT_SAAS,
	},
	{
		name: "Software como servicio",
		description:
			"Con el fin de ofrecer los mejores servicios y productos a nuestros clientes, hemos creado aplicaciones basadas en la web y en la Nube. Esto permite que nuestros usuarios no se encuentren limitados de ninguna forma posible al momento de revisar las transacciones de su negocio.",
		image: PRODUCT_FINANCIAL_APPS,
	},
	{
		name: "Dispositivos ecológicos",
		description:
			"Cada uno de nuestros aplicativos financieros está diseñado para funcionar sin necesidad de emitir facturas impresas, además de permitir el envío de recibos y reportes transaccionales por SMS o email.",
		image: PRODUCT_ECO_APPS,
	},
	{
		name: "Certificaciones nacionales de pago",
		description: `Como parte del cumplimiento de todas las normativas nacionales de seguridad y de procesamiento de pagos, además de ofrecer la mejor calidad y seguridad a sus clientes, SitioUno posee todas y cada una de las certificaciones necesarias que requiere el ámbito bancario, en la actualidad.`,
		image: PRODUCT_NP,
	},
	{
		name: "Certificaciones internacionales de pago",
		description: `Nuestros clientes pueden tener la seguridad de que todos sus negocios están protegidos con nosotros y son respaldados por prestigiosas organizaciones en Estados Unidos, tales como PCI SSC o First Data.`,
		image: PRODUCT_IPC,
	},
	{
		name: "Surfing",
		description: `Plataforma propietaria para la transmisión segura, masiva y automatizada de archivos de gran tamaño, mediante el uso de la tecnología Cloud, de Google Storage, y que cumple con todos los estándares regulatorios PCI para el almacenamiento de datos.`,
		image: PRODUCT_SURFING,
	},
];

const JOIN_COMPANY = {
	pretitle: "About us",
	subtitle: "Entonces, ¿te animas a ser parte de nosotros?",
	text: "Atrévete a vivir la experiencia de ser parte de un equipo de trabajo enfocado en la creación de las mejores soluciones tecnológicas del mercado. Disfruta de todos los beneficios que solo SitioUno puede ofrecerte.",
	button: "Únete al Equipo",
	link: "/Jobs#Crew",
};

const JOIN_HOME = {
	pretitle: "Sobre nosotros",
	subtitle: "Forma parte de la experiencia",
	text: "Trabaja de la mano de un equipo altamente capacitado en el campo de la tecnología y enfocado en la creación de soluciones fáciles de usar, seguras e ideadas con conciencia ambiental.",
	button: "Únete al Equipo",
	link: "/Jobs#Crew",
	image: LEMASPANISH,
};

const JOIN_STUDENTS = {
	pretitle: "About us",
	subtitle: "Genera el impacto que el mundo necesita",
	text: "Formamos profesionales que combinan habilidades técnicas con creatividad y pensamiento crítico. Nuestro enfoque práctico te prepara para enfrentar los desafíos del mundo real y destacar en el mercado laboral.",
	button: "Únete al Equipo",
	link: "/Jobs#Crew",
	image: LEMASPANISH,
};

const NAV_BAR = {
	join: "Únete",
	link: "/Jobs",
};

const STUDENTS_HEADER = {
	active: "Students",
	sloganPt1: "Descubre tu talento con nosotros",
	sloganPt2: "y desarróllalo",
};

const STUDENTS_HERO = {
	subtitle: "Trabaja y estudia",
	text: "SitioUno es el lugar perfecto para aquellos que están desarrollando una carrera en el mundo de la tecnología. Aquí podrán vivir la experiencia de trabajar en una de las mejores empresas de tecnología del país, sin tener que abandonar o dejar a un lado sus estudios. Apreciamos tu talento y deseos de desarrollo profesional y te apoyaremos para que juntos podamos crecer.",
};

const SHOW_OFF = {
	number: "03",
	header: "Curriculum y portafolio",
	subtitle: "Cuéntanos de tí",
	description1: "Adjunta tu CV y/o portafolio",
	description2: "Este archivo no debe tener más de 10 MB JPEG, PDF, DOC",
	desc_replace: "Click para reemplazar tu archivo",
	description3: "Usa esta opción en caso de tener portafolio online",
	description4: "Portafolio",
	imagen1: ARROW1,
	imagen2: ARROW2,
	imagen3: ERROR,
	submit: "Enviar",
	dragnDrop: "Arrastrar y soltar o cargar",
	dragnDropValidation: "Por favor, añade un documento tipo .pdf .jpg .docx",
	linkValidation:
		"Por favor, introduce un enlace válido. Asegúrate de incluir 'http://' o 'https://' al inicio",
};

const TEAMS = {
	pretitle: "Equipos de trabajo",
	subtitle: "Amamos la diversidad",
	text: "No nos importa quien seas, nos importa lo que haces.",
};

const CORE = {
	pretitle: "Equipos de trabajo",
	subtitle: "Conoce al Core.",
	text: "Conoce a los equipos que hacen que nuestros sueños se conviertan en productos y brinden soluciones en la vida y en el día a día de todos nuestro usuarios.",
};

const DEVELOPMENT1 = {
	image: DEVELOPMENT,
	teamName: "Desarrollo e Investigación",
	image_description: DEV_DESCRIPTION,
	objective:
		"El corazón del negocio. Son los encargados de darle vida a nuestros productos a través del desarrollo de software y de distintas tecnologías para convertir nuestras ideas en productos reales e innovadores; haciendo posible que nuestros productos evolucionen constantemente y con ellos nuestra empresa.",
};

const DESIGN = {
	image: DESIGN1,
	teamName: "Diseño",
	image_description: DESIGN_DESCRIPTION,
	list: [
		"Dominio de herramientas de diseño",
		"Amplio conocimiento sobre diferentes estilos gráficos y artísticos.",
	],
	objective:
		"El departamento de Diseño se encarga del desarrollo de la línea grafica de los productos, de los componentes de las soluciones tecnológicas y del diseño de la imagen corporativa de todo el material gráfico y audiovisual producido en SitioUno.",
};

const TECHNICALSERVICE1 = {
	image: TECHNICALSERVICE,
	teamName: "Servicio Técnico",
	image_description: IT_DESCRIPTION,
	objective:
		"Nuestros técnicos son los responsables de atender todos los requerimientos relacionados al diagnóstico, reparación, y soluciones integrales asociadas al mercado de medios de pago, y POS. Respondiendo de manera efectiva y con sello de calidad internacional.",
};

const QA1 = {
	image: QA,
	teamName: "Departamento de productos",
	image_description: OP_DESCRIPTION,
	objective: `El departamento de QA se encarga, principalmente, de supervisar cada fase del proceso de desarrollo de las soluciones de pago y productos de SitioUno, con el fin que estos cumplan con los estándares de calidad de la empresa y los usuarios finales.`,
};

const IT1 = {
	image: IT,
	teamName: "Tecnología de la información (IT)",
	image_description: TS_DESCRIPTION,
	objective:
		"La seguridad de la información y de nuestros sistemas de comunicación están en manos de un equipo maravilloso, que no solo atiende nuestras necesidades operativas sino que con gran diligencia y un sentido de ATC excepcional nos hacen aportes para mejorar nuestras vidas y nuestro trabajo.",
};

const DATAANALYTICS1 = {
	image: DATAANALYTICS,
	teamName: "Analítica de datos (Data Analytics)",
	image_description: DATA_DESCRIPTION,
	objective:
		"El estudio del comportamiento de nuestro mercado y usuarios está en manos de un equipo que busca utilizar la información bajo un enfoque estratégico que nos permite hacer proyecciones y planificación para mejorar la vida de todos a través de nuestras áreas de negocio, y de esta forma evaluar las adaptaciones y mejoras continuas en nuestros productos y servicios.",
};

const PROJECTS1 = {
	image: PROJECTS,
	teamName: "Proyectos",
	image_description: PROYECTOS_DESCRIPTION,
	objective:
		"El elemento que impulsa el engranaje de todas las piezas que conforman nuestro Core de Negocios. Desde proyectos se integran y alinean todos los requerimientos de nuestros clientes y el talento de nuestro equipo para hacer que las cosas no solo funcionen, sino que lo hagamos bien.",
};

const ART = {
	subtitle1: "Arte",
	subtitle2: "Programar.",
	text: "Actividad en la que se recrea con múltiples finalidades, un aspecto de la realidad o una solución a un problema de forma artística valiéndose de la creatividad, inspiración y conocimiento.",
};

const THANKS = {
	title: "¡Gracias!",
	text: "Revisaremos los archivos que envió. Si su perfil coincide con un puesto vacante, lo llamaremos de inmediato.",
};

const TOPICS = {
	image1: LOGOITSU,
	image2: TECHMINDSBACKGROUND,
	pretitle: "Educación",
	subtitle: "Transforma tu Futuro con IA y Educación Técnica",
	text: `En ITSU, formamos profesionales en oficios altamente demandados, integrando inteligencia artificial (IA) para maximizar su productividad y eficiencia. `,
	text2: "Aprende y Prepárate para el Mercado Laboral en Solo 1 Año.",
	button: "SABER MAS",
};

const STUDENTS_TOPICS = {
	image: TECHMINDS2,
	subtitle: "Proyectos de la vida real",
	text: `Participa de manera activa en proyectos con visión de futuro que requieren del personal más capacitado en la actualidad. Sé parte del equipo de trabajo que ayude a hacer realidad productos ideados y diseñados dentro del corazón de SitioUno.`,
};

const MODERN_ORGANIZATION = {
	image: TECHMINDS3,
	subtitle: "Vive la experiencia de una organización moderna.",
	text: `Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ipsa incidunt quia, recusandae numquam harum rerum sunt, nihil, nemo expedita esse atque quae illo repellendus totam! Autem nam minus reprehenderit ratione?.`,
};

const WORKPLACE = {
	pretitle: "Área de trabajo",
	subtitle:
		"La gente innovadora no necesita que le digas que lo sea, sino que les permitas serlo.",
	text: `Somos parte de una generación que vive la tecnología y que quiere definir un estándar en el mundo de la innovación. SitioUno está integrado por los mejores profesionales y que están concentrados en la creación de productos únicos y de gran calidad.
    Queremos que seas parte de nosotros.
    ¿Tienes lo que se necesita?`,
};

const APP_HEADER = {
	active: "Apps",
	sloganPt1: "",
	sloganPt2: "Nuestros aplicativos",
};

const APP_BESOUL = {
	pretitle: "",
	subtitle: "",
	text: {
		one: "Be Soul es el lugar donde puedes preservar y revivir el recuerdo de tus seres queridos.",
		two: "A través de Be Soul, podrás guardar tus momentos más preciados y gestionar información valiosa en tus legados, asegurando que se mantengan a salvo para ser compartidos con los que más te importan, Post-mortem, en una fecha específica o de inmediato.",
		three:
			"Rinde homenaje a quienes ya no están con la función de memoriales, honrar a esas personas de manera única y significativa.",
		button: "Ir a la web",
		title_list:
			"Be Soul es una app dedicada a los recuerdos y al autoconocimiento. En ella, no solo podrás crear legados y memoriales significativos para tus seres queridos, sino que también podrás:",
		list: [
			{
				text: "Crear tu ruta semanal de legados: recibe desafíos semanales que te ayudarán a crear legados más profundos y significativos.",
			},
			{
				text: 'Función "Vivir el ahora": una herramienta que te recuerda lo valioso de vivir el presente.',
			},
			{
				text: "Número maestro: descubre tus números guías y su poder en tu vida.",
			},
			{
				text: "Interpretación de sueños: descubre lo que tus sueños tienen para decirte.",
			},
			{
				text: "Carta natal: recibe el mensaje del universo para ti.",
			},
			{
				text: "Oráculo: hazle preguntas significativas al oráculo y recibe respuestas.",
			},
			{
				text: "Soulguide: conversa con Soulguide y recibe apoyo en tu camino.",
			},
		],
	},
};

const APP_SWAGGIN = {
	pretitle: "",
	subtitle: "",
	text: {
		one: "Swaggin es una rocola digital que brinda un ambiente de interacción compartida donde la música es seleccionada entre todos los clientes de un local.",
		two: "Diseñada para descentralizar la responsabilidad de elegir el ambiente musical, automatizando el proceso, mejorar la experiencia y promoviendo la interacción del cliente.",
		coming_soon: "Disponible próximamente",
		three:
			"Con Swaggin, no solo mejoras la experiencia de tus clientes, sino que también transformas la música en una fuente de ingresos extra.",
		button: "Ir a la web",
		title_list:
			"Swaggin es una aplicación innovadora e interactiva que te permitirá:",
		list: [
			{
				text: "Ofrecer una experiencia única, personalizada e interactiva a los clientes de tu local o taxi.",
			},
			{
				text: "Crear listas de reproducción personalizadas con los estilos musicales que prefieras.",
			},
			{
				text: "Utilizar YouTube para reproducir la música seleccionada.",
			},
			{
				text: "Monetizar tu servicio: los clientes pueden comprar canciones para que suenen en el local o taxi, y tú ganas un porcentaje por cada canción agregada.",
			},
			{
				text: "Crear paquetes que faciliten el acceso a la plataforma, generando ingresos adicionales.",
			},
			{
				text: "Definir condiciones de uso para mantener la armonía del ambiente musical.",
			},
		],
	},
};

const APP_EAR = {
	pretitle: "",
	subtitle: "",
	left: {
		text: {
			one: "EAR: La Plataforma Inteligente que Transforma tu Negocio",
			two: "En Sitio Uno, te presentamos EAR, la plataforma integral que combina la inteligencia artificial con un sistema de gestión empresarial completo. EAR no es solo un chatbot; es la solución para optimizar cada área de tu negocio y mejorar la experiencia de tus clientes.",
			button: "Ir a la Web",
		},
	},
	right: {
		title: "¿Qué hace a EAR único?",
		subtitle:
			"EAR te ofrece una gestión 360° de tu empresa, impulsada por la IA:",
		elements: [
			{
				title:
					"Comunicación Inteligente: EAR gestiona todas tus comunicaciones con agentes inteligentes que brindan respuestas instantáneas y personalizadas a tus clientes, 24/7.",
			},
			{
				title:
					"Gestión Integral: EAR incluye un sistema de gestión empresarial para:",
				list: [
					{ text: "Automatizar tu Marketing" },
					{ text: "Gestionar tu Sitio Web y E-commerce" },
					{ text: "Optimizar tu CRM" },
					{ text: "Simplificar tu Facturación" },
					{ text: "Organizar tus Proyectos y mucho más." },
				],
			},
			{
				title:
					"IA para Optimizar Procesos: EAR utiliza la IA para automatizar tareas:",
				list: [
					{ text: "Atención al Cliente con agentes virtuales inteligentes." },
					{ text: "Gestión de Citas automatizada." },
					{ text: "Gestión de Llamadas con agentes virtuales." },
					{ text: "Gestión de Correos Electrónicos automatizada." },
					{ text: "Gestión de SMS personalizada." },
				],
			},
			{
				title: "Beneficios de EAR para tu empresa:",
				list: [
					{ text: "Mejora la experiencia de tus clientes con atención 24/7." },
					{
						text: "Aumenta la eficiencia y reduce costos al automatizar procesos.",
					},
					{ text: "Soporte experto de nuestro equipo bilingüe." },
				],
			},
		],

		text1: "Sitio Uno: Tu aliado estratégico para la transformación digital",
		text2:
			"Descubre cómo EAR puede transformar tu negocio. ¡Contáctanos hoy mismo!",
	},
};

const APP_HUBFINTECH = {
	pretitle: "",
	subtitle: "",
	left: {
		text: {
			one: "HubFintech.ai: Innovación Inteligente para el Sector Financiero",
			two: "En SitioUno, presentamos HubFintech.ai, una plataforma avanzada que integra inteligencia artificial para transformar y modernizar las operaciones de bancos y fintechs. Diseñada para mejorar la eficiencia operativa y fortalecer la seguridad, HubFintech.ai ofrece soluciones adaptativas que evolucionan junto con las necesidades del mercado financiero.​",
			button: "Ir a la Web",
		},
	},
	right: {
		elements: [
			{
				title: "Características Destacadas de HubFintech.ai:",
				list: [
					{
						text: "Sistema Bancario Modular: Permite a las instituciones financieras actualizar su infraestructura de manera progresiva, asegurando una integración sin interrupciones y una escalabilidad óptima.​",
					},
					{
						text: "Agentes de Prevención de Fraude con IA: Implementa herramientas inteligentes que detectan y previenen actividades fraudulentas en tiempo real, protegiendo tanto a la institución como a sus clientes.​​",
					},
					{
						text: "Asistentes Virtuales Bancarios: Facilita interacciones eficientes y seguras con los clientes a través de plataformas como WhatsApp, ofreciendo respuestas rápidas y personalizadas las 24 horas del día.​​",
					},
				],
			},
			{
				title: "Beneficios de HubFintech.ai para su Institución:",
				list: [
					{
						text: "Mejora en la Experiencia del Cliente: Proporciona servicios más rápidos y personalizados, aumentando la satisfacción y fidelidad de los clientes.​",
					},
					{
						text: "Eficiencia Operativa: Automatiza procesos clave, reduciendo costos y minimizando errores humanos.​",
					},
					{
						text: "Cumplimiento Normativo: Asegura el cumplimiento con estándares internacionales como PCI y PADSS, garantizando operaciones seguras y conformes a las regulaciones vigentes.",
					},
					{
						text: "Con HubFintech.ai, SitioUno reafirma su compromiso con la innovación y la excelencia en el desarrollo de soluciones tecnológicas que impulsan el crecimiento y la transformación digital en el sector financiero.​",
					},
				],
			},
		],

		text1: "Sitio Uno: Tu aliado estratégico para la transformación digital",
		text2:
			"Descubre cómo EAR puede transformar tu negocio. ¡Contáctanos hoy mismo!",
	},
};

const B2B_HEADER = {
	active: "B2B",
	sloganPt1: "Soluciones Tecnológicas a la medida",
	sloganPt2: "",
	subtitle:
		"Descubre nuestro servicio B2B de desarrollo de aplicaciones y páginas web",
};

const B2B_SERVICES = {
	pretitle: "Servicio B2B",
	subtitle: "Servicio B2B",
	text: "Nuestro equipo se especializa en el desarrollo de aplicaciones y sitios web personalizados para empresas, utilizando tecnologías avanzadas como Gcloud y MongoDB. Estamos comprometidos en proporcionar soluciones innovadoras que cumplan de forma óptima las necesidades de tu negocio. ¡Permítenos ayudarte a llevar tu presencia en línea al siguiente nivel!",
};

const B2B_IDEA = {
	pretitle: "¿Cómo llevamos a cabo tu idea?",
	subtitle: "¿Cómo llevamos a cabo tu idea?",
	text: "Queremos asegurarnos de que cada solución que creamos sea única y personalizada para satisfacer las necesidades específicas de tu empresa. Aquí te explicamos los pasos que seguimos para hacerlo:",
};

const B2B_STEPS = {
	pretitle: "¿Cómo llevamos a cabo tu idea?",
	subtitle: "¿Cómo llevamos a cabo tu idea?",
	steps: [
		{
			bullet: "01",
			title: "Investigación de Mercado (Research):",
			subtitle:
				"Primero, hacemos una investigación exhaustiva del mercado y la competencia para asegurarnos que el producto sea original y competitivo. Esto nos ayuda a identificar las tendencias actuales y futuras y adaptar nuestra solución para tu empresa.",
			image: B2B_RESEARCH,
		},
		{
			bullet: "02",
			title: "Identidad de Marca (Marketing):",
			subtitle:
				"Luego trabajaremos contigo para desarrollar o ajustar la identidad de marca, de manera que sea sólida y se adapte a tus valores y objetivos, lo cual incluye toda la imagen y voz que tu empresa proyecta. Juntos, nos aseguraremos de crear una identidad coherente que transmita el mensaje correcto a tu público objetivo.",
			image: B2B_MARKETING,
		},
		{
			bullet: "03",
			title: "Propuesta de Diseño (UX/UI):",
			subtitle:
				"Nuestro equipo de diseño creará propuestas personalizadas que se adapten a la investigación previa de research y marketing. Te presentamos varias opciones y trabajamos contigo para asegurarnos que el diseño final represente fielmente tu marca.",
			image: B2B_DESIGN,
		},
		{
			bullet: "04",
			title: "Tu aprobación: ",
			subtitle:
				"Una vez que hayamos presentado la propuesta de diseño final, esperamos que la apruebes antes de continuar con el desarrollo. Queremos asegurarnos de que estés completamente satisfecho antes de avanzar.",
			image: B2B_APROBATION,
		},
		{
			bullet: "05",
			title: "Desarrollo (Programadores):",
			subtitle:
				"Nuestro equipo de desarrollo aprovecha una amplia gama de tecnologías avanzadas para crear su solución personalizada. Utilizamos Google Cloud Platform (GCP) y su conjunto de productos, Google Workspace, MongoDB, así como herramientas y servicios de Microsoft. Con nuestro enfoque en la escalabilidad, eficiencia y fácil mantenimiento, nos aseguramos de que su solución satisfaga sus necesidades en constante evolución. Además, aprovechamos el poder de la computación en la nube, el software como servicio (SaaS), las herramientas de inteligencia empresarial (BI), el aprendizaje automático (Machine Learning) y la inteligencia artificial (AI) para mejorar las capacidades de su solución. A lo largo del proceso de desarrollo, priorizamos la comunicación transparente, manteniéndolo informado en cada etapa, y nuestro objetivo final es superar sus expectativas con el resultado final.",
			image: B2B_DEVELOMENT,
		},
		{ bullet: "06" },
	],
};

const B2B_CONTACT = {
	pretitle:
		"¡Estamos emocionados por trabajar contigo y crear la solución perfecta para tu empresa!",
	subtitle:
		"¡Estamos emocionados por trabajar contigo y crear la solución perfecta para tu empresa!",
	button: "Contáctanos",
};

const B2B_FORM = {
	pretitle: "Información para el servicio B2B",
	title: "Información para el servicio B2B",
	subtitle: "Cuéntanos sobre tu empresa",
	name: {
		label: "Nombre completo",
		placeholder: "Tu nombre completo",
		error: "Campo requerido. Ingresa tu nombre completo",
	},
	company: {
		label: "Nombre de la empresa",
		placeholder: "Nombre de la empresa",
		error: "Campo requerido. Ingresa el nombre de la empresa",
	},
	email: {
		label: "Correo empresarial",
		placeholder: "Correo empresarial",
		error: "Campo requerido. Ingresa un email válido",
	},
	phone: {
		label: "Número de teléfono",
		placeholder: "Número de teléfono Ej. +1XXXXXXXXXX",
		error: "Campo requerido. Ingresa un número de teléfono",
	},
	briefDescription: {
		label: "Breve descripción del proyecto *",
		placeholder:
			"Ej: Estoy interesado en crear una aplicación móvil para mi tienda de productos electrónicos. La aplicación debe permitir a los usuarios explorar nuestro catálogo de productos, hacer compras en línea, recibir notificaciones de ofertas y promociones, y mantenerse actualizados sobre las últimas novedades en tecnología. Además, la aplicación debe contar con una interfaz fácil de usar y atractiva, y ser compatible con diferentes dispositivos móviles y sistemas operativos. El objetivo principal de esta aplicación es mejorar la experiencia de compra de nuestros clientes y aumentar nuestras ventas en línea.",
		error:
			"Campo requerido. Por favor, proporciona una breve descripción del proyecto",
	},
	button: "Enviar",
};

const B2B_DIALOG = {
	pretitle: "¡Datos enviados!",
	subtitle: "¡Datos enviados!",
	text: "Gracias por tomarte el tiempo de llenar nuestro formulario. Apreciamos tu interés en nuestros servicios y nos pondremos en contacto pronto para discutir más detalles sobre tu proyecto",
	button: " < Volver al inicio",
};

const TMA_HEADER = {
	active: "TechmindsAcademy",
	sloganPt1: "Academia TechMinds",
	sloganPt2: "Fundada por el equipo de SU",
};

const TMA_MISION = {
	pretitle: "Nuestra misión",
	subtitle: "Nuestra misión",
	text: "Nuestra misión es brindar a los estudiantes una educación de calidad que los prepare para el mundo tecnológico en constante evolución. Nos esforzamos por ofrecer programas de estudio actualizados y relevantes que equipen a los estudiantes con las habilidades necesarias para sobresalir en la industria de la tecnología.",
};

const TMA_STANDUP = {
	pretitle: "Destaca en tu formación con nosotros",
	subtitle: "Destaca en tu formación con nosotros",
	text: "En techminds.academy estamos respaldados por gigantes como ¨Microsoft, Google, Universidad Central de Venezuela, El Ministerio del Poder Popular para la Educación, Certiport,  y Mongo DB University¨",
};

const TMA_FUTURE = {
	pretitle: "Únete y domina las habilidades del futuro",
	subtitle: "Únete y domina las habilidades del futuro",
	text: "Sumérgete en nuestros emocionantes cursos que incluyen el dominio de herramientas indispensables como Adobe Illustrator y Adobe Photoshop. Además, explorarás el fascinante mundo del agilismo sin fronteras, abriéndote a infinitas posibilidades de crecimiento.",
};

const TMA_COURSES = {
	pretitle: "Cursos disponibles actualmente",
	subtitle: "Cursos disponibles actualmente",
	text1: "Adobe Illustrator",
	text2: "Adobe Photoshop",
	text3: "Agilismo sin fronteras",
};

const TMA_ACCREDITATIONS = {
	pretitle: "Estamos acreditados por:",
	subtitle: "Estamos acreditados por:",
};

const TMA_AUTHORIZED = {
	pretitle: "Centro autorizado de pruebas: ",
	subtitle: "Centro autorizado de pruebas: ",
};

const TMA_INDUSTRY = {
	pretitle: "Reconocimiento en la industria",
	subtitle: "Reconocimiento en la industria",
	text: "Al completar con éxito nuestros programas de estudio, los estudiantes obtienen certificaciones respaldadas por los líderes tecnológicos. Estas certificaciones son reconocidas en la industria y pueden abrir puertas a oportunidades laborales y colaboraciones profesionales.",
};

const TMA_VISIT = {
	pretitle: "¡Prepárate para el éxito en el campo de la tecnología !",
	subtitle: "¡Prepárate para el éxito en el campo de la tecnología !",
	button: "Visitar Techminds",
};

const COOKIES_HEADER = {
	active: "cookies",
	sloganPt1: "",
	sloganPt2: "Consentimiento de cookies",
};

const COOKIES_CONSENT = {
	title: "Cookies",
	subtitle: {
		p1: "Este sitio utiliza cookies para ofrecerle una mejor experiencia de navegación.",
		p2: "Obtenga más información",
		p3: "sobre cómo usamos las cookies y cómo puede cambiar su configuración.",
	},
	buttons: {
		config: "Configuración",
		acceptAll: "Aceptar cookies",
	},
};

const COOKIES_CONFIG = {
	title: "Cookies",
	options: {
		op1: {
			title: "Aceptar TODAS las cookies del sitio",
			subtitle:
				"Al aceptar usar TODAS las cookies de nuestro sitio, permite el uso de las cookies tanto nuestras incluyendo Microsoft Clarity y Google Analytics, con las cuales analizamos los datos basicos de navegación para brindar una mejor experiencia a nuestros usuarios.",
		},
		op2: {
			title: "Microsoft Clarity",
			content: {
				title: "Microsoft Clarity:",
				subtitle1: {
					bold: "Cookies analíticas o de rendimiento:",
					text: "Nos permite reconocer y contar la cantidad de visitantes, distinguir cómo se mueven los visitantes por el Sitio cuando lo usan. Esto nos ayuda a mejorar la manera en que funciona el Sitio.",
				},
				subtitle2: {
					bold: "Cookies de focalización:",
					text: "Registra tu visita al Sitio, las páginas que visitaste y los enlaces que seguiste. Usaremos esta información para que este Sitio y el contenido de este se relacionen más con tus intereses. También compartimos esta información con terceros con esta finalidad.",
				},
				subtitle3: {
					bold: "Cookies de terceros:",
					text: "Además de nuestras propias cookies, es posible que también usemos cookies de terceros para informar estadísticas de uso del Sitio y perfeccionar las estrategias de marketing. Cookies de seguimiento: Realizan un seguimiento del comportamiento en el Sitio y lo vinculan con otras métricas, lo que permite un mejor conocimiento de los hábitos de uso.",
				},
			},
		},
		op3: {
			title: "Google Analytics",
			content: {
				title: "Google Analytics:",
				subtitle1: {
					bold: "Cookies analíticas o de rendimiento.",
					text: "Nos permite reconocer y contar la cantidad de visitantes, distinguir cómo se mueven los visitantes por el Sitio cuando lo usan. Esto nos ayuda a mejorar la manera en que funciona el Sitio.",
				},
				subtitle2: {
					bold: "Cookies de focalización.",
					text: "Registra tu visita al Sitio, las páginas que visitaste y los enlaces que seguiste. Usaremos esta información para que este Sitio y el contenido de este se relacionen más con tus intereses. También compartimos esta información con terceros con esta finalidad.",
				},
				subtitle3: {
					bold: "Cookies de terceros.",
					text: "Además de nuestras propias cookies, es posible que también usemos cookies de terceros para informar estadísticas de uso del Sitio y perfeccionar las estrategias de marketing.",
				},
				subtitle4: {
					bold: "Cookies de optimización.",
					text: "Permite un seguimiento en tiempo real de la conversión del usuario de diferentes canales de marketing para evaluar su efectividad.",
				},
			},
		},
	},
	buttons: {
		acceptAll: "Aceptar todas las cookies",
		more: "Más información",
		save: "Aceptar cookies",
	},
};

const COOKIES_UTILITY = {
	title: "Políticas de Cookies",
	subtitle: "¿Qué Cookies utilizamos en Sitio Uno?",
	text: {
		p: {
			parraf_1: {
				bold: "Cookies analíticas o de rendimiento.",
				content:
					"Nos permite reconocer y contar la cantidad de visitantes, distinguir cómo se mueven los visitantes por el Sitio cuando lo usan. Esto nos ayuda a mejorar la manera en que funciona el Sitio.",
			},
			parraf_2: {
				bold: "Cookies de focalización.",
				content:
					"Registra tu visita al Sitio, las páginas que visitaste y los enlaces que seguiste. Usaremos esta información para que este Sitio y el contenido de este se relacionen más con tus intereses. También compartimos esta información con terceros con esta finalidad.",
			},
			parraf_3: {
				bold: "Cookies de terceros.",
				content:
					"Además de nuestras propias cookies, es posible que también usemos cookies de terceros para informar estadísticas de uso del Sitio y perfeccionar las estrategias de marketing.",
			},
			parraf_4: {
				bold: "Cookies de seguimiento.",
				content:
					"Realizan un seguimiento del comportamiento en el Sitio y lo vinculan con otras métricas, lo que permite un mejor conocimiento de los hábitos de uso.",
			},
			parraf_5: {
				bold: "Cookies de optimización",
				content:
					"Permite un seguimiento en tiempo real de la conversión del usuario de diferentes canales de marketing para evaluar su efectividad.",
			},
		},
	},
};

const COOKIES_SERVICES = {
	subtitle: "¿Que servicios de cookies utilizamos en Sitio Uno?",
	text: {
		p: {
			parraf_1: {
				bold: "1. Microsoft Clarity:",
				content:
					"El servicio de cookies de Microsoft Clarity es una herramienta de análisis de sitios web que utiliza cookies para recopilar información sobre el comportamiento de los usuarios, como los clics y la navegación. Esto ayuda a los propietarios de sitios web a comprender cómo los visitantes interactúan con su sitio y a tomar decisiones informadas para mejorarlo.",
			},
			parraf_2: {
				bold: "Cookies de focalización.",
				content:
					"El servicio de cookies de Google Analytics es una plataforma de análisis de sitios web que utiliza cookies para recopilar información sobre el comportamiento de los usuarios en un sitio web. Esto incluye datos como la ubicación geográfica, el dispositivo utilizado y las páginas visitadas. Los propietarios de sitios web pueden utilizar esta información para mejorar la experiencia del usuario y optimizar su sitio para aumentar el tráfico y las conversiones.",
			},
		},
	},
};

const PrivacyPolicy_HEADER = {
	active: "PrivacyPolicy",
	sloganPt1: "",
	sloganPt2: "",
};

const TermsConditions_HEADER = {
	active: "TermsConditions",
	sloganPt1: "",
	sloganPt2: "",
};

const BOT_CONFIG = {
	title: "Sitio Uno",
	subtitle: "Desarrollamos Soluciones tecnológicas",
	inputPlaceholder: "Hola! quiero saber más de Sitio Uno",
	initialMessages: { 1: "Hola!", 2: "¿En qué puedo ayudarte?" },
};

const Spanish = {
	BACK,
	BACK_HOME,
	BOT_CONFIG,
	COMPANY_HEADER,
	COMPANY_BENEFITS,
	CREW_HEADER,
	CREW,
	FOOTER_LINKS,
	LEADER,
	LEADER_VALUES,
	FEATURES_VALUES,
	FEATURES1,
	FEATURES2,
	FEATURES3,
	FEATURES4,
	FEATURES5,
	FEATURES6,
	NAVLINKS,
	NEXT,
	HEADER,
	INTERN_BENEFITS,
	INTERN_QUOTE,
	JOBS_HEADER,
	OFFICE_CAROUSEL,
	OUR_WORK,
	OUR_WORK2,
	OUR_WORK3,
	OUR_WORK5,
	OUR_WORK4,
	OUR_WORK6,
	ABOUT_US,
	ABOUT_US1,
	ABOUT_US2,
	ABOUT_US3,
	PERFORMANCE,
	PERFORMANCE_COUNTERS,
	PERSONAL_INFO,
	PRODUCTS,
	BENEFITSLIST,
	JOIN_HOME,
	JOIN_COMPANY,
	JOIN_STUDENTS,
	NAV_BAR,
	STUDENTS_HEADER,
	STUDENTS_HERO,
	SHOW_OFF,
	TEAMS,
	CORE,
	DEVELOPMENT1,
	DESIGN,
	TECHNICALSERVICE1,
	QA1,
	IT1,
	DATAANALYTICS1,
	PROJECTS1,
	ART,
	THANKS,
	TOPICS,
	STUDENTS_TOPICS,
	MODERN_ORGANIZATION,
	WORKPLACE,
	APP_HEADER,
	APP_BESOUL,
	APP_SWAGGIN,
	APP_EAR,
	APP_HUBFINTECH,
	B2B_HEADER,
	B2B_SERVICES,
	B2B_IDEA,
	B2B_STEPS,
	B2B_CONTACT,
	B2B_FORM,
	B2B_DIALOG,
	TMA_HEADER,
	TMA_MISION,
	TMA_STANDUP,
	TMA_FUTURE,
	TMA_COURSES,
	TMA_ACCREDITATIONS,
	TMA_AUTHORIZED,
	TMA_VISIT,
	TMA_INDUSTRY,
	COOKIES_HEADER,
	COOKIES_CONSENT,
	COOKIES_CONFIG,
	COOKIES_UTILITY,
	COOKIES_SERVICES,
	PrivacyPolicy_HEADER,
	TermsConditions_HEADER,
};

export default Spanish;
