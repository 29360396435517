import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";
import createRootReducer from "./ducks";
import { createStore } from "redux";
import history from "./history";
import "typeface-raleway";
import { MantineProvider } from "@mantine/core";

const store = createStore(createRootReducer);

ReactDOM.render(
	<MantineProvider withGlobalStyles withNormalizeCSS>
		<Provider store={store}>
			<Router history={history}>
				<App />
			</Router>
		</Provider>
	</MantineProvider>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//
serviceWorker.unregister();
