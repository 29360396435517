import React from "react";
import {
	Button,
	Flex,
	Image,
	List,
	Space,
	Text,
	createStyles,
} from "@mantine/core";

// assets
// import BesoulApp1_EN from "../../assets/images/BeSoul/BeSoul1_EN.png";
// import BesoulApp1_ES from "../../assets/images/BeSoul/BeSoul1_ES.png";
// import BesoulApp2_EN from "../../assets/images/BeSoul/BeSoul2_EN.png";
// import BesoulApp2_ES from "../../assets/images/BeSoul/BeSoul2_ES.png";
import Besoul from "../../assets/images/besoul.svg";
import Apple_EN from "../../assets/svg/markets/AppStore_EN.svg";
import Play_EN from "../../assets/svg/markets/PlayStore_EN.svg";
import Apple_ES from "../../assets/images/apple.svg";
import Play_ES from "../../assets/images/play.svg";
import { ASSETS_BASE_URL } from "../../constants";

const useStyles = createStyles(() => ({
	image: {
		"@media (max-width: 1500px)": {
			margin: "auto",
			width: "100%",
			maxWidth: 400,
		},
	},
}));

const BeSoul = ({ content }) => {
	const { classes } = useStyles();
	const { APP_BESOUL } = content;
	const isClientES = window.localStorage.getItem("locale") === "es";

	return (
		<>
			<Flex
				id="anovale"
				direction={{
					base: "column",
					sm: "column",
					xs: "column",
					md: "row",
					lg: "row",
					xl: "row",
				}}
				align="center"
				justify="center"
				w={{ base: "90%", md: "100%" }}
				maw={1200}
			>
				<Flex direction={{ base: "column" }} w={"90%"}>
					<Image
						src={Besoul}
						maw={{ md: 448 }}
						mah={{ md: 84 }}
						mt={{ md: 100 }}
						m="md"
						width={{ base: "90%", md: 350 }}
					/>

					<Text size={20} align="left" maw={568} mt={50}>
						{APP_BESOUL.text.one}
						<Space h="md" />
						{APP_BESOUL.text.two}
						<Space h="md" />
						{APP_BESOUL.text.three}
					</Text>
				</Flex>
				<Image
					src={`${ASSETS_BASE_URL}/${
						isClientES ? "SU_Web_Apps_Besoul_1_ESP" : "SU_Web_Apps_Besoul_1_ENG"
					}.webp`}
					mah={{ md: 600 }}
					maw={{ base: "250px", md: 400 }}
					mt={{ base: 50, md: 0 }}
					mb={{ base: 50, md: 0 }}
					// ml={-100}
					// mt={100}
					// className={classes.image}
				/>
			</Flex>
			<Flex
				direction={{
					base: "column-reverse",
					md: "row",
					lg: "row",
					xl: "row",
				}}
				align="center"
				justify="center"
				mt={{ md: 100 }}
			>
				<Flex
					direction="column"
					align="center"
					justify="center"
					style={{ width: "100%" }}
					mr={{ md: 100 }}
				>
					<Image
						src={`${ASSETS_BASE_URL}/${
							isClientES
								? "SU_Web_Apps_Besoul_2_ESP"
								: "SU_Web_Apps_Besoul_2_ENG"
						}.webp`}
						mah={{ md: 600 }}
						maw={{ base: "200px", md: 250 }}
						w={{ base: "80%", md: "auto" }}
						// style={{ width: "80%" }}
					/>
					<Button
						uppercase
						component="a"
						target="_blank"
						href="https://besoul.app/"
						style={{
							backgroundColor: "#002E81",
							color: "#FFF",
							fontSize: 16,
							width: "100%",
							maxWidth: 163,
							marginTop: 50,
						}}
						radius={42}
					>
						{APP_BESOUL.text.button}
					</Button>
				</Flex>
				<Flex m="sm" direction="column" align="center" justify="center">
					<Text size={20}>{APP_BESOUL.text.title_list}</Text>
					<List size={20} withPadding>
						{[0, 1, 2, 3, 4, 5].map((item) => (
							<List.Item key={item}>
								<Text color="#5C6166">{APP_BESOUL.text.list[item].text}</Text>
							</List.Item>
						))}
					</List>
					<Flex direction="row" align="center" justify="center" mt="xl">
						<Image
							src={isClientES ? Play_ES : Play_EN}
							m="md"
							component="a"
							href="https://play.google.com/store/apps/details?id=com.sitiouno.mytestament.prod&pli=1"
							target="_blank"
						/>
						<Image
							src={isClientES ? Apple_ES : Apple_EN}
							m="md"
							component="a"
							href="https://apps.apple.com/us/app/be-soul/id6444774949"
							target="_blank"
						/>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default BeSoul;
