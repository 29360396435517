import React from "react";
import { Row, Col } from "react-bootstrap";
import { Flex, Image, Container } from "@mantine/core";
import styled from "styled-components";

import Footer from "../sections/Footer";
import Besoul from "../assets/images/besoul.svg";
import ILLUSTRATION_HEADER from "../assets/images/BotsProducts.svg";
import Divider from "../assets/images/Divider.svg";
import BeSoul from "../sections/aplications/BeSoul";
import Swaggin from "../sections/aplications/Swaggin";
import HeaderSections from "../sections/HeaderSections";
import { useMediaQuery } from "@mantine/hooks";
import { Helmet } from "react-helmet";
import Ear from "../sections/aplications/Ear";
import HubFinTech from "../sections/aplications/HubFinTech";

const IFrame = styled.iframe`
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    display: block;
    height: 340px;

    @media screen and (max-width: 767px) {
        height: 300px;
    }

    @media screen and (min-width: 768px) and (max-width: 800px) {
        height: 380px;
        max-width: 95%;
    }
    @media screen and (min-width: 801px) and (max-width: 1024px) {
        height: 460px;
        max-width: 95%;
    }
    @media screen and (min-width: 1400px) and (max-width: 1524px)
        height: 620px;
        max-width: 95%;
    }
    @media screen and (min-width: 1525px){
        height: 880px;
        max-width: 95%;
    }
    @media screen and (min-width: 1920px) {
        height: 340px;
        max-width: 100%;
        width: 692px;
    }
    @media screen and (min-width: 2000px){
        height: 940px;
        max-width: 100%;
    }
    @media screen and (min-width: 2560px){
        height: 1200px;
        max-width: 100%;
    }
`;

const Applications = (props) => {
	const { APP_HEADER } = props.content;
	const isPhone = useMediaQuery("(max-width: 1000px)");
	const isClientES = window.localStorage.getItem("locale") === "es";

	return (
		<Row>
			<Helmet>
				{process.env.REACT_APP_IS_PROD_ENV === "false" && (
					<meta name="robots" content="noindex, nofollow" />
				)}
				<link rel="canonical" href="https://sitiouno.com/Apps" />
				<meta name="title" content={APP_HEADER.active} />
				<meta
					name="description"
					content={`${APP_HEADER.sloganPt1} ${APP_HEADER.sloganPt2}`}
				/>
				<title>{APP_HEADER.active}</title>
			</Helmet>
			<Col>
				<HeaderSections
					content={props.content}
					active={APP_HEADER.active}
					sloganPt1={APP_HEADER.sloganPt1}
					sloganPt2={APP_HEADER.sloganPt2}
					changeLanguage={props.changeLanguage}
					language={props.language}
					imageHeader={ILLUSTRATION_HEADER}
					isProducts
					id="Apps"
				/>
				<Container style={{ marginBottom: 200 }}>
					<Flex mt={isPhone ? 30 : 150}>
						<IFrame
							className="my-3"
							src={`https://www.youtube.com/embed/W9O0Bfz5HRE`}
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
							style={{ width: "100%", height: "100%", aspectRatio: "2/1" }}
						/>
					</Flex>
					{/* <Flex
						justify="flex-start"
						id="logo-besoul"
						align="center"
						direction={{
							base: "row",
							xs: "column",
							sm: "column",
							md: "row",
							lg: "row",
							xl: "row",
						}}
					>
						<Image src={Besoul} maw={448} mah={84} mt={100} m="md" />
					</Flex> */}
					<Flex
						direction="column"
						align="center"
						justify="center"
						mt="xl"
						style={{ color: "#5C6166" }}
					>
						<BeSoul content={props.content} />
						<Image src={Divider} maw={300} h="auto" mt={100} />
						<Swaggin content={props.content} />
						<Ear content={props.content} />
						<HubFinTech content={props.content} />
					</Flex>
				</Container>
				<Footer
					content={props.content}
					changeLanguage={props.changeLanguage}
					language={props.language}
				/>
			</Col>
		</Row>
	);
};

export default Applications;
