import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { HashLink as Link } from "react-router-hash-link";
import theme from "../styles/theme";
import Text from "../components/Text";
import TextTile2 from "../components/TextTile2";
import Button1 from "../components/Button1";
import { ASSETS_BASE_URL } from "../constants";

const Background = styled(Row)``;
const Illustration = styled.img`
	max-height: 100%;
	max-width: 2000px;
	width: 100%;
	height: auto;

	@media screen and (max-width: 576px) {
		width: 100%;
		position: relative;
	}

	@media screen and (min-width: 577px) and (max-width: 900px) {
		width: 100%;
	}

	@media screen and (min-width: 901px) and (max-width: 1300px) {
		width: 100%;
		position: relative;
	}

	@media screen and (min-width: 1500px) and (max-width: 1700px) {
		width: 100%;
	}

	@media screen and (min-width: 1701px) and (max-width: 1999px) {
		width: 100%;
	}

	@media screen and (min-width: 2000px) {
		width: 100%;
	}
`;
const EventHandler = () => {
	window.dataLayer.push({
		event: "join_students",
	});
};
class JoinStudents extends React.Component {
	render() {
		const { JOIN_STUDENTS } = this.props.content;

		const { link, button } = this.props;

		return (
			<Background
				className="justify-content-center pt-6 mt-5"
				id="JoinStudents"
			>
				<Col xs={11} md={11} lg={9}>
					<TextTile2
						pretitle={JOIN_STUDENTS.pretitle}
						subtitle={JOIN_STUDENTS.subtitle}
						text={JOIN_STUDENTS.text}
						alignment="justify"
						className="mx-5 my-5 px-3"
					></TextTile2>
					<Row className="justify-content-left">
						<Col xs="auto" className="my-3">
							<Link to={link}>
								<Button1
									onClick={EventHandler}
									color={theme.colors.primary}
									fontcolor={theme.colors.white}
									className="px-4"
								>
									<Text
										color={theme.colors.white}
										fontSize={theme.sizes.link}
										fontWeight="bold"
										className="my-0"
									>
										{button}
									</Text>
								</Button1>
							</Link>
						</Col>
					</Row>
				</Col>
				<Illustration
					src={`${ASSETS_BASE_URL}/SU_Web_Students_Footer.webp`}
				></Illustration>
				{/* <Col
					xs={10}
					md={10}
					lg={12}
					className="mb-3"
					style={{ marginTop: 50 }}
				></Col> */}
			</Background>
		);
	}
}

export default JoinStudents;
