import React from "react";
import { Button, Flex, Image, List, Space, Text } from "@mantine/core";
import EAR_Logo_3 from "../../assets/svg/logotypes/EAR_Logo_3.svg";
import { ASSETS_BASE_URL } from "../../constants";

const Ear = ({ content }) => {
	const { APP_EAR } = content;
	// const isClientES = window.localStorage.getItem("locale") === "es";

	return (
		<div style={{ width: "100%", maxWidth: 1200, marginTop: 100 }}>
			<Flex direction={{ md: "row" }} justify={{ md: "center" }}>
				<Image
					src={EAR_Logo_3}
					maw={{ base: "300px", md: 350 }}
					mah={84}
					m="md"
					mb={100}
					mx={{ base: "auto", md: 0 }}
				/>
			</Flex>
			<Flex
				direction={{ base: "column", md: "row" }}
				justify={{ md: "space-between" }}
				align={{ base: "center", md: "start" }}
				w="100%"
			>
				<Flex
					direction={{ base: "column" }}
					align="center"
					justify={{ md: "start" }}
					w={{ base: "90%", md: "47%" }}
				>
					<Text size={20} align="left" maw={568}>
						{APP_EAR.left.text.one}
						<Space h="md" />
						{APP_EAR.left.text.two}
					</Text>

					<Button
						uppercase
						component="a"
						target="_blank"
						href="https://www.ear.app/"
						style={{
							backgroundColor: "#002E81",
							color: "#FFF",
							fontSize: 16,
							width: "100%",
							maxWidth: 163,
							marginBottom: 50,
							marginTop: 50,
						}}
						radius={42}
					>
						{APP_EAR.left.text.button}
					</Button>

					<Image
						src={`${ASSETS_BASE_URL}/SU_Web_Apps_EAR.webp`}
						style={{ height: "100%", width: "80%" }}
						display={{ base: "none", md: "block" }}
					/>
				</Flex>
				<Flex
					direction={{ base: "column" }}
					align="center"
					justify={{ md: "start" }}
					w={{ base: "90%", md: "47%" }}
					mt={{ base: 50, md: 0 }}
				>
					<Text size={20} align="left" maw={568}>
						{APP_EAR.right.title}
						<Space h="md" />
						{APP_EAR.right.subtitle}
					</Text>

					<Space h="md" />

					{APP_EAR.right.elements.map((element, index) => (
						<div style={{ marginBottom: 20 }} key={index}>
							<Text size={20}>{element.title}</Text>
							{element.list && (
								<List size={20} mt={15} withPadding>
									{element.list.map((item, index) => (
										<List.Item key={index}>
											<Text color="#5C6166">{item.text}</Text>
										</List.Item>
									))}
								</List>
							)}
						</div>
					))}

					<Image
						src={`${ASSETS_BASE_URL}/SU_Web_Apps_EAR.webp`}
						style={{ height: "100%", width: "80%" }}
						display={{ md: "none" }}
					/>
				</Flex>
			</Flex>
		</div>
	);
};

export default Ear;
