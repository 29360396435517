import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import theme from "../styles/theme";
import NavComponent from "../components/NavComponent";
// import NavigationBar from "../components/NavigationBar";
import Text from "../components/Text";
import DAYHOME from "../assets/images/DayHome.jpg";
import NIGHTHOME from "../assets/images/NightHome.jpg";
import SPACE from "../assets/images/Space.jpg";
import ARROW from "../assets/images/ArrowWork.png";
import { UnoBotGroup } from "../components/UnoBotGroup/UnoBotGroup";
import { Link } from "react-router-dom";
import Button1 from "../components/Button1";
import MediaQuery from "react-responsive";

const Background = styled(Row)`
	padding: 0px 20px 0px 20px;
	position: relative;
`;
const HeaderHome = styled.div`
	position: absolute;
	background-image: url(${DAYHOME});
	min-width: 100%;
	min-height: 200%;
	background-size: cover;
`;
const HeaderNight = styled.div`
	position: absolute;
	background-image: url(${NIGHTHOME});
	min-width: 100%;
	min-height: 200%;
	background-size: cover;
`;
const HeaderJobs = styled.div`
	position: absolute;
	background-image: url(${SPACE});
	min-width: 100%;
	min-height: 200%;
	background-size: cover;
`;
const IllustrationJobs = styled.img`
	max-height: 80%;
	max-width: 80%;
	margin-top: 20px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);

	@media screen and (max-width: 576px) {
		max-height: 70%;
		max-width: 70%;
		padding-top: 0px;
		margin-top: 20px;
	}
	@media only screen and (min-width: 320px) and (max-width: 767px) {
		max-height: 100%;
		max-width: 400px;
		padding-top: 0px;
		margin-top: 70px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}

	@media screen and (min-width: 1900px) {
		max-height: 100%;
		max-width: 100%;
		padding-top: 120px;
		margin-top: 25px;
		margin-left: 120px;
	}
	@media screen and (min-width: 2000px) {
		max-height: 100%;
		max-width: 100%;
		padding-top: 180px;
		margin-top: 180px;
		margin-left: 220px;
	}
`;
const Arrow = styled.img`
	display: block;
	margin: auto;
	cursor: pointer;
`;

class Header extends React.Component {
	render() {
		const { active, sloganPt1, sloganPt2, sloganBold, header, id, image } =
			this.props;

		const scrollToBottom = () => {
			if (window.matchMedia("(max-width: 576px)").matches) {
				window.scroll(0, 400);
			} else {
				window.scrollTo({ top: 1050, behavior: "smooth" });
			}
		};

		return (
			<Background className="justify-content-center" id={id}>
				{active === "Home" &&
				new Date().getHours() >= 6 &&
				new Date().getHours() <= 18 ? (
					<HeaderHome />
				) : (
					<HeaderNight />
				)}

				{active === "Jobs" ? <HeaderJobs /> : null}

				<Col xs={12} md={12} lg={12}>
					<NavComponent
						content={this.props.content}
						active={active}
						changeLanguage={this.props.changeLanguage}
						language={this.props.language}
					/>
					<Row className="my-lg-5 px-lg-3">
						<Col
							xs={12}
							md={12}
							lg={12}
							className="my-sm-4 py-sm-4 my-md-4 py-md-4 my-lg-5 py-lg-5 text-break"
						>
							<Text
								className="my-3 my-md-2"
								color={theme.colors.white}
								fontSize={theme.sizes.title}
								lineHeight="126%"
								textAlign="center"
							>
								{sloganPt1}
								<br />
								{sloganBold}
								{sloganPt2}
							</Text>

							{active === "Home" ? (
								<Row className="row justify-content-around" xs={2} md={2}>
									<MediaQuery query="(max-width: 375px)">
										<Link to="/Jobs">
											<Button1
												color={theme.colors.primary}
												fontcolor={theme.colors.white}
												className="col-12 mr-xs-0 mx-0"
											>
												<Text
													className="my-0"
													color={theme.colors.white}
													fontSize={theme.sizes.link}
													fontWeight="bold"
													textAlign="center"
												>
													Únete
												</Text>
											</Button1>
										</Link>
									</MediaQuery>
								</Row>
							) : null}

							{active === "Jobs" ? (
								<>
									<Text
										color={theme.colors.white}
										fontSize={theme.sizes.header}
										lineHeight="126%"
										textAlign="center"
									>
										{header}
									</Text>
									<Col lg={12}>
										<Arrow src={ARROW} onClick={scrollToBottom} />
									</Col>
								</>
							) : null}
						</Col>
						<Col xs={12} md={3} lg={3}></Col>
						<Col xs={12} md={6} lg={6} className="mx-5 mb-lg-0 text-break">
							{active === "Home" ? <UnoBotGroup /> : null}
							{active === "Jobs" ? <IllustrationJobs src={image} /> : null}
						</Col>
						<Col xs={12} md={3} lg={3}></Col>
					</Row>
				</Col>
			</Background>
		);
	}
}

export default Header;
