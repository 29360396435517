import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Navbar, Nav, Dropdown, ButtonGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import { changeLocale } from "../ducks/localization";
import GLOBE from "../assets/svg/traduccionIconWhite.svg";
import GLOBEDARK from "../assets/svg/traduccionIcon.svg";
import LOGOBLANCO from "../assets/images/LogoBlanco.png";
import LOGOAZUL from "../assets/images/LogoSUazul.svg";
import theme from "../styles/theme";
import Button1 from "./Button1";
import Button2 from "./Button2";
import Text from "./Text";
// import MediaQuery from "react-responsive";
import "./Navbar.css";
import { Indicator, createStyles } from "@mantine/core";

const useStyles = createStyles(() => ({
	indicator: {
		"& .mantine-Indicator-indicator": {
			fontSize: 9,
		},
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
}));

const eventHandler = () => {
	window.dataLayer.push({
		event: "button_language",
	});
};

const joinHandler = () => {
	window.dataLayer.push({
		event: "join_header",
	});
};

const Icon = styled.img`
	height: 20px;
`;
const LogoIcon = styled.img`
	width: 130px;
	transition: 0.2s ease-in;
	@media screen and (min-width: 2000px) {
		margin-left: 60px;
	}
`;
const NavLink = styled(Link)`
	color: ${(props) => props.color} !important;
	font-size: ${theme.sizes.link};
	text-decoration: none;
	text-transform: uppercase;
	margin-right: 40px;
	&:hover {
		text-decoration: none;
	}
	&:after {
		display: block;
		content: "";
		border-bottom: ${(props) =>
			props.active === 1 ? "" : `solid 3px ${theme.colors.primary}`};
		transform: scaleX(0);
		transition: transform 250ms ease-in-out;
	}
	&:hover:after {
		transform: scaleX(1);
	}
`;
const LineBlue = styled(Col)`
	height: 3px;
	background: ${theme.colors.primary};
	width: 100%;
	@media screen and (min-width: 320px) and (max-width: 1100px) {
		width: 20%;
		margin-left: 40%;
		margin-right: 40%;
	}
`;
const LineWhite = styled(Col)`
	height: 3px;
	background: ${theme.colors.white};
	width: 100%;
	@media screen and (min-width: 320px) and (max-width: 1100px) {
		width: 20%;
		margin-left: 40%;
		margin-right: 40%;
	}
`;
const Toogle = styled(Dropdown.Toggle)`
	&:after {
		display: none;
	}
`;
const CustomRow = styled(Row)`
	align-items: center;
`;

const RenderNavLinks = (props, navbar) => {
	const { NAVLINKS, OUR_WORK2 } = props.content;
	const { classes } = useStyles();
	const { active } = props;

	const getPropLinks = (active, t) => {
		switch (active) {
			case "Home":
				return new Date().getHours() >= 6 && new Date().getHours() <= 18
					? {
							active: active === t.label ? 1 : 0,
							indicator: !(t.label === "Aplicativos" || t.label === "Apps"),
							color: "#002E81",
					  }
					: {
							active: active === t.label ? 1 : 0,
							indicator: !(t.label === "Aplicativos" || t.label === "Apps"),
							color: "#8BEBAF",
					  };
			case "Jobs":
				return {
					active: active === t.label ? 1 : 0,
					indicator: !(t.label === "Aplicativos" || t.label === "Apps"),
					color: !navbar ? "#8BEBAF" : "#002E81",
					line:
						active === t.label ||
						(t.label === "Empleo" && active === "Jobs") ? (
							navbar ? (
								<LineBlue xs={12} className="mt-0" />
							) : (
								<LineWhite xs={12} className="mt-0" />
							)
						) : null,
				};
			default:
				return {
					active:
						active === t.label ||
						active === "Compañía" ||
						active === "B2B Services"
							? 1
							: 0,
					indicator: !(
						(t.label === "Aplicativos" || t.label === "Apps") &&
						active !== "Apps"
					),
					color: active === "TechmindsAcademy" ? "#8BEBAF" : "#002E81",
					line:
						active === t.label ||
						(t.label === "Compañía" && active === "Company") ||
						(t.label === "B2B Services" && active === "B2B") ||
						(t.label === "Servicios B2B" && active === "B2B") ||
						(t.label === "Aplicativos" && active === "Apps") ||
						(t.label === "Estudiantes" && active === "Students") ? (
							<LineBlue xs={12} className="mt-0" />
						) : t.label === "Techminds academy" &&
						  active === "TechmindsAcademy" ? (
							navbar ? (
								<LineBlue xs={12} className="mt-0" />
							) : (
								<LineWhite xs={12} className="mt-0" />
							)
						) : null,
				};
		}
	};

	return NAVLINKS.map((t) => {
		return (
			<NavLink
				key={t.label}
				active={getPropLinks(active, t)["active"]}
				to={t.link}
				color={navbar ? theme.colors.darkerBlack : theme.colors.white}
				className={`${active === t.label ? "font-weight-bold" : ""}`}
			>
				<Indicator
					disabled={getPropLinks(active, t)["indicator"]}
					label={OUR_WORK2.label}
					size={18}
					className={classes.indicator}
					color={getPropLinks(active, t)["color"]}
					offset={-10}
				>
					{t.label}
				</Indicator>
				{getPropLinks(active, t)["line"]}
			</NavLink>
		);
	});
};

const NavComponent = (props) => {
	const locale = window.localStorage.getItem("locale");
	const language = locale === "en" ? "English" : "Español";
	// eslint-disable-next-line no-unused-vars
	const [lang, setLanguage] = useState("en");
	const { NAV_BAR } = props.content;
	const { active } = props;
	const [navbar, setNavbar] = useState(false);
	const [responsive, setResponsive] = useState(false);
	const prevProps = useRef();

	useEffect(() => {
		changeFixed();
		if (props.locale !== prevProps.locale) {
			setLanguage(props.locale);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.locale]);

	const changeBackground = () => {
		if (window.scrollY >= 80) {
			setNavbar(true);
		} else {
			setNavbar(false);
		}
	};

	const changeFixed = () => {
		if (window.innerWidth < 992) {
			setResponsive(true);
		} else {
			setResponsive(false);
		}
	};

	const getLogoIcon = (active) => {
		switch (active) {
			case "Home":
				return new Date().getHours() >= 6 && new Date().getHours() <= 18 ? (
					<LogoIcon src={LOGOAZUL} />
				) : navbar ? (
					<LogoIcon src={LOGOAZUL} />
				) : (
					<LogoIcon src={LOGOBLANCO} />
				);
			case "Jobs":
				return <LogoIcon src={navbar ? LOGOAZUL : LOGOBLANCO} />;
			case "TechmindsAcademy":
				return <LogoIcon src={navbar ? LOGOAZUL : LOGOBLANCO} />;

			default:
				return <LogoIcon src={LOGOAZUL} />;
		}
	};

	const getJoinButton = (active) => {
		switch (active) {
			case "Home":
				return new Date().getHours() >= 6 && new Date().getHours() <= 18
					? {
							button: {
								color: theme.colors.primary,
								fontcolor: theme.colors.white,
							},
							text: {
								color: theme.colors.white,
							},
					  }
					: {
							button: {
								color: navbar ? theme.colors.primary : theme.colors.white,
								fontcolor: theme.colors.primary,
							},
							text: {
								color: navbar ? theme.colors.white : theme.colors.darkerBlack,
							},
					  };
			case "Jobs":
				return {
					button: {
						color: navbar ? theme.colors.primary : theme.colors.white,
						fontcolor: theme.colors.primary,
						// width={"120px"}
						// height={"40px"}
						// marginTop={"25px"}
					},
					text: {
						color: navbar ? theme.colors.white : theme.colors.darkerBlack,
					},
				};
			case "TechmindsAcademy":
				return {
					button: {
						color: navbar ? theme.colors.primary : theme.colors.white,
						fontcolor: theme.colors.primary,
						// width={"120px"}
						// height={"40px"}
						// marginTop={"25px"}
					},
					text: {
						color: navbar ? theme.colors.white : theme.colors.darkerBlack,
					},
				};

			default:
				return {
					button: {
						color: theme.colors.primary,
						fontcolor: theme.colors.white,
					},
					text: {
						color: theme.colors.white,
					},
				};
		}
	};

	window.addEventListener("scroll", changeBackground);
	window.addEventListener("resize", changeFixed);

	return (
		<Navbar
			id="NavComponent"
			className={navbar ? "nav active" : responsive ? "nav" : "nav"}
			fixed={!responsive ? "top" : !navbar ? "" : "top"}
			collapseOnSelect
			expand="lg"
			variant="dark"
		>
			<Navbar.Brand href="/">{getLogoIcon(active)}</Navbar.Brand>
			<Navbar.Toggle
				aria-controls="responsive-navbar-nav"
				style={{
					backgroundColor: responsive && navbar && theme.colors.primary,
				}}
			/>
			<Navbar.Collapse
				className="row justify-content-around"
				id="responsive-navbar-nav"
			>
				<Col xs={12} lg={9}>
					<Nav id="nav-links" className="mt-3 mt-lg-3">
						{RenderNavLinks(props, navbar)}
					</Nav>
				</Col>
				<Col xs={12} lg={3}>
					<Nav className="row justify-content-end">
						<Col xs={12} lg={6} className="mt-2">
							<Dropdown as={ButtonGroup} className="row w-100 px-0">
								<Col xs={12} className="pr-0 pl-3 pl-lg-0">
									<Toogle className="shadow-none pt-0 p-0 col-12">
										<Button2
											bordercolor="transparent"
											className="col-12"
											onClick={eventHandler}
										>
											<CustomRow className="justify-content-center px-auto">
												<Col xs="auto" className="px-0">
													<Icon src={navbar ? GLOBEDARK : GLOBE} alt="Globe" />
												</Col>
												<Col
													xs="auto"
													className="text-truncate px-lg-0 px-xl-2"
												>
													<Text
														className="my-0"
														color={
															navbar ? theme.colors.black : theme.colors.white
														}
														fontSize={theme.sizes.link}
														fontWeight="bold"
														textAlign="center"
													>
														{language}
													</Text>
												</Col>
											</CustomRow>
										</Button2>
									</Toogle>
								</Col>
								<Dropdown.Menu className="col-12">
									<Dropdown.Item onClick={() => props.changeLocale("en")}>
										English
									</Dropdown.Item>
									<Dropdown.Item onClick={() => props.changeLocale("es")}>
										Español
									</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
						{active !== "Jobs" ? (
							<Col xs={12} lg={5} className="pl-1 mt-1">
								<Link to={NAV_BAR.link}>
									<Button1
										onClick={joinHandler}
										className="col-12 mr-xs-0 mx-0"
										{...getJoinButton(active)["button"]}
									>
										<Text
											className="my-0"
											fontSize={theme.sizes.link}
											fontWeight="bold"
											textAlign="center"
											{...getJoinButton(active)["text"]}
										>
											{NAV_BAR.join}
										</Text>
									</Button1>
								</Link>
							</Col>
						) : null}
					</Nav>
				</Col>
			</Navbar.Collapse>
		</Navbar>
	);
};

const mapStateToProps = ({ localization }) => {
	return {
		...localization,
		locale: localization.locale,
	};
};

const mapDispatchToProps = {
	changeLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavComponent);
