import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, useLocation, withRouter } from "react-router-dom";
import { ScrollToTop as Scroll } from "react-to-top";
import styled from "styled-components";
import { setAnalyticsCollectionEnabled } from "firebase/analytics";
import {
	useDisclosure,
	useLocalStorage,
	useSessionStorage,
} from "@mantine/hooks";
import { clarity } from "react-microsoft-clarity";
import { clarity as ClarityHandler } from "clarity-js";

import english from "./content/english";
import spanish from "./content/spanish";
import { changeState } from "./ducks/form";
import { changeLocale } from "./ducks/localization";
import Company from "./pages/Company";
import Crew from "./pages/Crew";
import Home from "./pages/Home";
import PersonalInformation from "./pages/PersonalInformation";
import ShowOff from "./pages/ShowOff";
import Students from "./pages/Students";
import Applications from "./pages/Applications";
import BusinessServices from "./pages/BusinessServices";
// import TechMinds from "./pages/TechMinds";
import Cookies from "./pages/Cookies";
import Consent from "./components/Consent.js";
import Config from "./components/Config.js";
import { analytics as FirebaseAnalytics } from "./firebase.js";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import { N8N_BOT_URL } from "./constants.js";
import "@n8n/chat/style.css";
import { createChat } from "@n8n/chat";
import "./styles/chat-styles.css";

const CLARITY_ID = process.env.REACT_APP_CLARITY_KEY;

const AppDiv = styled.div`
	overflow-x: hidden;
`;

function ScrollToTop() {
	const { pathname } = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

const App = (props) => {
	const locale = window.localStorage.getItem("locale");
	const content = locale === "en" ? english : spanish;
	const isPhone = window.matchMedia("(max-width: 320px)").matches;

	const [allowedCookies, setAllowed] = useLocalStorage({
		key: "allowed-cookies",
		defaultValue: [],
	});
	const [enableCookies, setCookies] = useSessionStorage({
		key: "enable-cookies",
		defaultValue: true,
	});
	const [openConsent, handler] = useState(false);
	const [config, configHandler] = useDisclosure(false);

	const toggleConsent = () => {
		handler((o) => !o);
	};

	useEffect(() => {
		createChat({
			webhookUrl: N8N_BOT_URL,
			initialMessages: [
				content.BOT_CONFIG.initialMessages[1],
				content.BOT_CONFIG.initialMessages[2],
			],
			i18n: {
				en: {
					title: content.BOT_CONFIG.title,
					subtitle: content.BOT_CONFIG.subtitle,
					footer: "",
					getStarted: content.BOT_CONFIG.getStarted,
					inputPlaceholder: content.BOT_CONFIG.inputPlaceholder,
					closeButtonTooltip: "",
				},
			},
		});
	}, []);

	useEffect(() => {
		if (enableCookies && allowedCookies.length !== 0) {
			handler(false);
		}
		if (allowedCookies.length === 0 && enableCookies) {
			handler(true);
		} else if (allowedCookies.length === 0 && !enableCookies) {
			handler(false);
		}
		if (allowedCookies.includes("clarity" && "analytics")) {
			clarity.init(CLARITY_ID);
			setAnalyticsCollectionEnabled(FirebaseAnalytics, true);
		} else if (
			allowedCookies.includes("clarity") &&
			!allowedCookies.includes("analytics")
		) {
			clarity.init(CLARITY_ID);
			setAnalyticsCollectionEnabled(FirebaseAnalytics, false);
		} else if (
			allowedCookies.includes("analytics") &&
			!allowedCookies.includes("clarity")
		) {
			setAnalyticsCollectionEnabled(FirebaseAnalytics, true);
			ClarityHandler.stop();
			window.addEventListener("consentGranted", () => window.clarity("stop"));
		} else if (allowedCookies.length === 0) {
			setAnalyticsCollectionEnabled(FirebaseAnalytics, false);
			window.addEventListener("consentGranted", () => window.clarity("stop"));
			ClarityHandler.stop();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [allowedCookies]);

	return (
		<AppDiv className="App">
			<Consent
				content={content}
				toggleConsent={toggleConsent}
				openConsent={openConsent}
				configHandler={configHandler}
				setAllowed={setAllowed}
				allowedCookies={allowedCookies}
				setCookies={setCookies}
			/>
			<Config
				content={content}
				config={config}
				configHandler={configHandler}
				setAllowed={setAllowed}
				allowedCookies={allowedCookies}
				setCookies={setCookies}
			/>
			<Scroll
				symbol="⮝"
				strokeFillColor="transparent"
				symbolColor="white"
				bgColor="#002E81"
				style={{ zIndex: "990", bottom: 100, right: 25 }}
			/>
			<ScrollToTop />
			<Route
				exact
				path="/"
				render={() => (
					<Home
						content={content}
						language={props.location}
						history={props.history}
					/>
				)}
			/>
			<Route
				exact
				path="/Company"
				render={() => (
					<Company
						content={content}
						language={props.location}
						history={props.history}
					/>
				)}
			/>
			<Route
				exact
				path="/Students"
				render={() => (
					<Students
						content={content}
						language={props.location}
						history={props.history}
					/>
				)}
			/>
			<Route
				exact
				path="/Jobs"
				render={() => <Crew content={content} history={props.history} />}
			/>

			<Route exact path="/Jobs/Personal">
				<PersonalInformation content={content} history={props.history} />
			</Route>
			<Route path="/Jobs/ShowOff">
				<ShowOff
					content={content}
					history={props.history}
					language={props.location}
				/>
			</Route>
			<Route
				exact
				path="/Apps"
				render={() => (
					<Applications
						content={content}
						language={props.location}
						history={props.history}
					/>
				)}
			/>
			<Route
				exact
				path="/B2B"
				render={() => (
					<BusinessServices
						content={content}
						language={props.location}
						history={props.history}
					/>
				)}
			/>
			{/* <Route
        exact
        path="/TechmindsAcademy"
        render={() => (
          <TechMinds
            content={content}
            language={props.location}
            history={props.history}
          />
        )}
      /> */}
			<Route
				exact
				path="/cookies-consent"
				render={() => (
					<Cookies
						content={content}
						language={props.location}
						history={props.history}
					/>
				)}
			/>
			<Route
				exact
				path="/PrivacyPolicy"
				render={() => (
					<PrivacyPolicy
						content={content}
						language={props.location}
						history={props.history}
					/>
				)}
			/>
			<Route
				exact
				path="/Terms&Conditions"
				render={() => (
					<TermsConditions
						content={content}
						language={props.location}
						history={props.history}
					/>
				)}
			/>
		</AppDiv>
	);
};

const mapStateToProps = ({ localization }) => {
	return {
		locale: localization.locale,
	};
};

const mapDispatchToProps = {
	changeLocale,
	changeState,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
