import React from "react";
import { Row, Col } from "react-bootstrap";
import TextTile from "../components/TextTile";
import styled from "styled-components";
import TextTile2 from "../components/TextTile2";
import { ASSETS_BASE_URL } from "../constants";

const IFrame = styled.iframe`
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    display: block;
    height: 620px;

    @media screen and (max-width: 767px) {
        height: 200px;
    }

    @media screen and (min-width: 768px) and (max-width: 800px) {
        height: 380px;
        max-width: 95%;
    }
    @media screen and (min-width: 801px) and (max-width: 1024px) {
        height: 460px;
        max-width: 95%;
    }
    @media screen and (min-width: 1400px) and (max-width: 1524px)
        height: 620px;
        max-width: 95%;
    }
    @media screen and (min-width: 1525px){
        height: 880px;
        max-width: 95%;
    }
    @media screen and (min-width: 1920px) {
        height: 900px;
        max-width: 100%;
    }
    @media screen and (min-width: 2000px){
        height: 940px;
        max-width: 100%;
    }
    @media screen and (min-width: 2560px){
        height: 1200px;
        max-width: 100%;
    }
`;
const Image = styled.img`
	width: 100%;
	height: auto;
	margin: 0 auto;
	display: block;
	@media only screen and (min-width: 320px) and (max-width: 767px) {
		width: 100%;
		max-height: 100%;
		object-fit: contain;
		max-width: 200%;
		padding-top: 10px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
	}
`;

const Image2 = styled.img`
	width: 100%;
	height: auto;
	margin: 0 auto;
	display: block;
	margin-bottom: 200px;
	@media only screen and (min-width: 320px) and (max-width: 767px) {
		width: 100%;
		max-height: 100%;
		object-fit: contain;
		max-width: 200%;
		padding-top: 10px;
		position: relative;
		left: 50%;
		transform: translateX(-50%);
		margin-bottom: 100px;
	}
`;
const Img = styled.img`
	width: 70%;
	height: auto;
	display: block;
	margin: 30px 0 30px 0;
	max-width: 100%;
`;

const TitleContainer = styled.div`
	margin-top: 200px;
	margin-bottom: 100px;

	@media (max-width: 768px) {
		margin-top: 50px;
		margin-bottom: 50px;
	}
`;

const StudentContainer = styled.div`
	margin-top: 300px;
	@media (max-width: 768px) {
		margin-top: 0;
	}
`;
class StudentsHero extends React.Component {
	render() {
		const { STUDENTS_HERO, TOPICS } = this.props.content;

		return (
			<StudentContainer id="StudentsHero">
				<Row className="justify-content-center mt-5 pt-5">
					<Col
						xs={{ order: 1, span: 11 }}
						md={{ order: 1, span: 11 }}
						lg={{ order: 1, span: 5 }}
						className="mx-5 my-lg-5 relative"
					>
						<TextTile2
							pretitle={TOPICS.pretitle}
							alignment="justify"
						></TextTile2>
						<Img src={TOPICS.image1}></Img>
						<TextTile2
							subtitle={TOPICS.subtitle}
							alignment=""
							lineHeightSubtitle={"50px"}
						></TextTile2>
						<TextTile2
							text={TOPICS.text}
							text2={TOPICS.text2}
							alignment="justify"
						></TextTile2>
						<a
							href="https://itsu.education/"
							target="_blank"
							style={{
								textDecoration: "none",
								color: "white",
								backgroundColor: "#002E81",
								borderRadius: "50px",
								padding: "16px 64px",
								position: "absolute",
								zIndex: 10,
							}}
						>
							{TOPICS.button}
						</a>
					</Col>
					<Col
						xs={{ order: 2, span: 9 }}
						md={{ order: 2, span: 5 }}
						lg={{ order: 2, span: 4 }}
						className="mx-5 m my-lg-5"
					>
						<Image src={TOPICS.image2} alignment="center" className="mt-5" />
					</Col>
				</Row>
				<Row className="justify-content-center mt-5 pt-5">
					<Col xs={11} md={11} lg={10} className="mb-5 pt-5">
						<IFrame
							className="my-3"
							src="https://www.youtube.com/embed/ppyoFRnHz9M"
							title="👨‍🏫✨ LOS MENTORES DEL FUTURO ESTÁN AQUÍ ✨👩‍🏫"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowfullscreen
						></IFrame>

						<TitleContainer>
							<TextTile
								subtitle={STUDENTS_HERO.subtitle}
								text={STUDENTS_HERO.text}
								alignment="justify"
							/>
						</TitleContainer>

						<Image2 src={`${ASSETS_BASE_URL}/SU_Web_Students_Image_1.webp`} />
					</Col>
				</Row>
			</StudentContainer>
		);
	}
}

export default StudentsHero;
