import React from "react";
import {
	Box,
	Button,
	Flex,
	Image,
	List,
	Space,
	Text,
	createStyles,
} from "@mantine/core";
import styled from "styled-components";

// assets
import SwagginImage from "../../assets/images/swaggin.svg";
// import SwagginApp from "../../assets/images/swaggin_app.svg";
// import SwagginApp2 from "../../assets/images/swaggin_app2.svg";
import Apple_EN from "../../assets/svg/markets/AppStore_EN.svg";
import Play_EN from "../../assets/svg/markets/PlayStore_EN.svg";
import Apple_ES from "../../assets/images/apple.svg";
import Play_ES from "../../assets/images/play.svg";
import { ASSETS_BASE_URL } from "../../constants";

const IFrame = styled.iframe`
    width: 100%;
    margin: 0 auto;
    border-radius: 15px;
    display: block;
    height: 340px;

    @media screen and (max-width: 767px) {
        height: 300px;
    }

    @media screen and (min-width: 768px) and (max-width: 800px) {
        height: 380px;
        max-width: 95%;
    }
    @media screen and (min-width: 801px) and (max-width: 1024px) {
        height: 460px;
        max-width: 95%;
    }
    @media screen and (min-width: 1400px) and (max-width: 1524px)
        height: 620px;
        max-width: 95%;
    }
    @media screen and (min-width: 1525px){
        height: 880px;
        max-width: 95%;
    }
    @media screen and (min-width: 1920px) {
        height: 340px;
        max-width: 100%;
        width: 692px;
    }
    @media screen and (min-width: 2000px){
        height: 940px;
        max-width: 100%;
    }
    @media screen and (min-width: 2560px){
        height: 1200px;
        max-width: 100%;
    }
`;

const useStyles = createStyles(() => ({
	image: {
		"@media (max-width: 1500px)": {
			margin: "auto",
			width: "100%",
			maxWidth: 400,
		},
	},
}));

const Swaggin = ({ content }) => {
	const { classes } = useStyles();
	const { APP_SWAGGIN } = content;
	const isClientES = window.localStorage.getItem("locale") === "es";

	return (
		<>
			<Flex mt={70} style={{ width: "100%", maxWidth: "auto" }}>
				<IFrame
					className="my-3"
					src={`https://www.youtube.com/embed/${
						isClientES ? "oMLAdf_1qRg" : "EhOJO3AzKFM"
					}`}
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
					style={{ width: "100%", height: "100%", aspectRatio: "2/1" }}
				/>
			</Flex>
			<Flex
				direction={{
					base: "column-reverse",
					md: "row",
					lg: "row",
					xl: "row",
				}}
				align="center"
				justify="center"
				mt={{ base: 50 }}
			>
				<Image
					className={classes.image}
					src={`${ASSETS_BASE_URL}/${
						isClientES
							? "SU_Web_Apps_Swaggin_1_ESP"
							: "SU_Web_Apps_Swaggin_1_ENG"
					}.webp`}
					maw={486}
					mah={730}
				/>
				<Flex direction="column" align="center" justify="center">
					<Image src={SwagginImage} maw={448} mah={125} />
					<Flex
						direction="column"
						maw={474}
						align="center"
						justify="center"
						m="xl"
					>
						<Text size={20}>{APP_SWAGGIN.text.one}</Text>
						<Space h="md" />
						<Text size={20}>{APP_SWAGGIN.text.two}</Text>
					</Flex>
					<Box
						direction="row"
						align="center"
						justify="center"
						mt="xl"
						display={{ base: "none", md: "flex" }}
					>
						<Image
							src={isClientES ? Play_ES : Play_EN}
							m="md"
							component="a"
							href="https://play.google.com/store/apps/details?id=com.sitiouno.cloudjukebox.prod"
							target="_blank"
						/>
						<Image
							src={isClientES ? Apple_ES : Apple_EN}
							m="md"
							component="a"
							href="https://apps.apple.com/us/app/swaggin-prod/id6446597129"
							target="_blank"
						/>
					</Box>
				</Flex>
			</Flex>
			<Flex
				direction={{
					base: "column",
					md: "row",
					sm: "column",
					xs: "column",
					lg: "row",
					xl: "row",
				}}
				align="center"
				justify="center"
			>
				<Flex
					direction="column"
					align="center"
					justify="center"
					maw={568}
					m="lg"
				>
					<Text size={20}>{APP_SWAGGIN.text.title_list}</Text>
					<List m="md" size={20} withPadding>
						{[0, 1, 2, 3, 4, 5].map((item) => (
							<List.Item key={item}>
								<Text color="#5C6166">{APP_SWAGGIN.text.list[item].text}</Text>
							</List.Item>
						))}
					</List>
					<Text color="#5C6166" size={20}>
						{APP_SWAGGIN.text.three}
					</Text>
					<Button
						uppercase
						component="a"
						target="_blank"
						href="https://swaggin.fun/"
						style={{
							backgroundColor: "#002E81",
							color: "#FFF",
							fontSize: 16,
							width: "100%",
							maxWidth: 163,
							marginTop: 50,
						}}
						radius={42}
					>
						{APP_SWAGGIN.text.button}
					</Button>
				</Flex>

				<Flex direction="column">
					<Image
						src={`${ASSETS_BASE_URL}/SU_Web_Apps_Swaggin_2.webp`}
						maw={421}
						mah={597}
						m="lg"
						className={classes.image}
					/>

					<Flex
						direction="row"
						align="center"
						justify="center"
						mt="xl"
						display={{ md: "none" }}
					>
						<Image
							src={isClientES ? Play_ES : Play_EN}
							m="md"
							component="a"
							href="https://play.google.com/store/apps/details?id=com.sitiouno.cloudjukebox.prod"
							target="_blank"
						/>
						<Image
							src={isClientES ? Apple_ES : Apple_EN}
							m="md"
							component="a"
							href="https://apps.apple.com/us/app/swaggin-prod/id6446597129"
							target="_blank"
						/>
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default Swaggin;
