export const SITE_ROOT =
	process.env.NODE_ENV === "production"
		? "http://localhost:8080"
		: "https://website-su-dot-infra-su-desarrollo.appspot.com/";

export const API_ROOT = `https://website-su-dot-infra-su-desarrollo.appspot.com/api`;

export const ASSETS_BASE_URL = process.env.REACT_APP_FIREBASE_ASSETS_BASE_URL;

export const N8N_BOT_URL = process.env.REACT_APP_N8N_BOT_URL;
